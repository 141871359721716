<template>
    <div
        class="upload-container"
        :style="{ width: width, height: height }"
    >
        <div class="progress-wrapper" v-if="isUploading">
            <div class="progress-text">
                正在上传文件
            </div>
            <v-progress-circular
                :rotate="-90"
                :size="100"
                :width="15"
                :value="progress"
                color="white"
            >
                {{ progress }}
            </v-progress-circular>
        </div>
        <div class="upload-wrapper" v-else>
            <input
                class="upload-input"
                ref="input"
                type="file"
                @change="handleFiles"
            />
            <div class="upload-trigger" 
                :class="{ dragging }"
                @click.stop="onClickTrigger"
                @dragenter="onDragenter"
                @dragleave="onDragleave"
                @dragover="onDragover"
                @drop="onDrop"
            >
                <!-- <slot></slot> -->
                <!-- <v-icon x-large dark> mdi-hospital </v-icon> -->
                <div class="cross"></div>
                <div class="text">点击或拖拽上传文件</div>
            </div>
        </div>
    </div>
</template>

<script>
import cos from "@/utils/cos";
import { getFileExtName } from "@/utils/util";

export default {
    props: {
        cosName: { type: String, default: "" },
        prefix: { type: String, default: "" },
        filename: { type: String, default: "file" },
        // type: { type: String, default: "" },

        width: { type: String, default: "320px" },
        height: { type: String, default: "220px" },
    },
    data() {
        return {
            files: [],
            dragging: false,

            progress: 0,
            isUploading: false,
        };
    },
    methods: {
        onClickTrigger() {
            this.$refs.input.click();
        },
        handleFiles(e) {
            // e.target.files is pseudo array, need to convert to real array
            const rawFiles = Array.from(e.target.files);
            // console.log(rawFiles);
            this.uploadFiles(rawFiles);
        },
        uploadFiles(rawFiles) {
            const filesLen = rawFiles.length + this.files.length;
            if (this.limit && this.limit < filesLen) {
                return this.onExceed(rawFiles, this.files);
            }
            this.startUpload(rawFiles);
        },
        startUpload(rawFiles) {
            rawFiles.forEach((rawFile) => {
                const file = this.normalizeFiles(rawFile);
                if (!this.beforeUpload || this.beforeUpload()) {
                    this.upload(file);
                }
            });
        },
        normalizeFiles(rawFile) {
            const file = {
                name: rawFile.name, // 文件名
                size: rawFile.size, // 文件尺寸
                type: rawFile.type, // 文件类型
                percent: 0, // 上传进度
                uid: Date.now() + this.tempIndex++, // 唯一标识
                status: "init", // value list: init pending success failure
                raw: rawFile, // 原生文件对象
            };
            // concat does not change the existing arrays, but instead returns a new array
            this.files.push(file);
            return file;
        },
        async upload(file) {
            if (this.checkFileType(file.type)) {
                let extName = getFileExtName(file.name)

                this.isUploading = true

                let success = await cos.putObject(this.cosName, this.prefix, this.filename + '.' + extName, file.raw, this.onProgress);
                // console.log('upload result: ' + success);
                if (success) {
                    this.$emit('uploadSuccess');
                    this.isUploading = false
                    this.progress = 0
                }
            }
        },
        checkFileType(type) {
            // TODO 增加支持的文件类型校验
            return true
        },
        onProgress(progress) {
            this.progress = Number(progress.loaded / progress.total).toFixed(2) * 100
        },

        // handleSuccess(file, response) {
        //     file.status = "success";
        //     this.$set(file, "response", response);
        //     // Not only front end can implement picture preview but also back end can do it. Here make use of back end api
        //     this.$set(file, "url", response.data.path);
        //     this.onSuccess(response, file, this.files);
        // },
        // handleError(file, error) {
        //     file.status = "failure";
        //     this.onError(error, file, this.files);
        // },
        // handleProgress(file, event) {
        //     file.percent = event.percent;
        //     this.onProgress(event, file, this.files);
        // },

        // 拖拽上传
        onDragenter(e) {
            this.dragging = true;
            e.stopPropagation();
            e.preventDefault();
        },
        onDragleave(e) {
            this.dragging = false;
            e.stopPropagation();
            e.preventDefault();
        },
        onDragover(e) {
            e.stopPropagation();
            e.preventDefault();
        },
        onDrop(e) {
            this.dragging = false;
            e.stopPropagation();
            e.preventDefault();

            const rawFiles = Array.from(e.dataTransfer.files);
            // console.log(rawFiles);
            this.uploadFiles(rawFiles);
        },
    },
};
</script>

<style lang="scss" scoped>
.upload-container {
    border: 2px dashed #eee;
    border-radius: 8px;

    .upload-wrapper {
        width: 100%;
        height: 100%;
    }

    .upload-input {
        display: none;
    }

    .upload-trigger {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;

        .text {
            font-size: 16px;
            color: #eee;
            margin-top: 20px;
            letter-spacing: 1px;
        }
    }
    .upload-trigger:hover {
        // background-color: #2a2a2a;
        background-color: #333;
    }

    .dragging {
        background-color: #333;
    }

    .progress-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .progress-text {
            font-size: 24px;
            font-weight: bold;
            color: #eee;
            margin-bottom: 10px;
        }
    }
}

.cross {
    margin: 10px 0;
    background: #ddd;
    height: 60px;
    position: relative;
    width: 10px;
}
.cross:after {
    background: #ddd;
    content: "";
    height: 10px;
    left: -25px;
    position: absolute;
    top: 25px;
    width: 60px;
}
</style>
