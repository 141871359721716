<template>
    <div class="video-container">
        <div class="content">

            <div class="progress-wrapper" v-show="isLoading">
                <div class="progress-text">
                    正在加载文件
                </div>
                <v-progress-circular
                    :size="80"
                    :width="7"
                    color="white"
                    indeterminate
                ></v-progress-circular>
            </div>
            <div class="upload-wrapper" v-show="!isUploaded && !isLoading">
                <Upload
                    :cosName="cosName"
                    :prefix="prefix"
                    :filename="filename"
                    @uploadSuccess="uploadSuccess"
                ></Upload>
            </div>
            <div class="pdf-wrapper" v-show="isUploaded && !isLoading">
                <!-- <pdf ref="pdf" :src="url"></pdf> -->
                <pdf
                    v-for="i in numPages"
                    :key="i"
                    :page="i"
                    :src="pdfDoc"
                    class="pdf"
                    style="width: 100%; height: auto;" 
                    @num-pages="pageCount=$event"
                >
                </pdf>
            </div>
        </div>
    </div>
</template>

<script>
import pdf from 'vue-pdf'
import { mapGetters } from 'vuex'
import Upload from '@/views/components/widget/Upload';

import { getCourseInfo, getSectionInfo } from '@/utils/common'
import { genResId } from '@/api/web'
import { getAdminSectionData, saveAdminSectionRes } from '@/api/admin'

export default {
    name: 'my-pdf',
    data: function() {
        return {
            resId: '',
            filename: '',

            cosName: 'doc',
            prefix: 'pdf',
            
            isUploaded: false,
            isUploading: false,

            // url: 'https://doc-1253970464.cos.ap-beijing.myqcloud.com/pdf/456.pdf',
            url: '',
            pdfDoc: null,
            pageCount: 0,
            numPages: 0,

            progress: 0,
            isLoading: false,
        }
    },
    computed: {
        ...mapGetters([
            'pid',
            'cid',
            'sid',
        ]),
    },
    activated() {
        // 加载页面参数
        let pid = ''
        let sid = ''
        let cid = ''
        if (this.$route.query.pid) {
            pid = this.$route.query.pid
        }
        if (this.$route.query.cid) {
            cid = this.$route.query.cid
        }
        if (this.$route.query.sid) {
            sid = this.$route.query.sid
        }

        let path = this.$route.path
        this.loadPage(path, cid, sid, pid)

        // this.prohibit()
    },
    deactivated() {
        this.isUploaded = false
        this.resId = ''
        this.filename = ''
        // this.pdfDoc = null

        this.pdfDoc.disableWorker = true

        this.isUploading = false
        this.isLoading = false
    },
    mounted() {
        // 加载userInfo
        this.loadUserInfo();
    },
    methods: {
        getCourseInfo,
        getSectionInfo,
        async loadUserInfo() {
            await this.$store.dispatch('user/getUserInfo');
        },
        loadPage(cid, sid, pid, curEnv = null) {
            if (curEnv) {
                this.curEnv = curEnv
            }

            if (this.curEnv == 'classroom') {
                this.loadClassroomData(cid, sid)

            } else if (this.curEnv == 'editor') {
                this.loadEditorData(cid, sid)
            }
        },
        async loadClassroomData(cid, sid) {
            this.$store.commit('web/SetCourseId', cid)
            this.$store.commit('web/SetSectionId', sid)

            // 获取小节resId
            let res = await getAdminSectionData({ cid, sid })
            if (res && res.data) {
                this.resId = res.data.resId
                this.filename = this.resId
            
                this.$store.commit('web/SetProjectId', this.resId)
            }

            // 加载上课信息
            this.getCourseInfo(cid)

            // 加载小节信息
            this.getSectionInfo(sid)

            // 加载小节数据
            this.loadData()
        },
        async loadEditorData(cid, sid) {
            this.$store.commit('web/SetCourseId', cid)
            this.$store.commit('web/SetSectionId', sid)

            // 获取小节resId
            let res = await getAdminSectionData({ cid, sid })
            if (res && res.data) {
                this.resId = res.data.resId
                this.filename = this.resId

                this.$store.commit('web/SetProjectId', this.resId)
            }

            // 加载小节信息
            this.getSectionInfo(sid)
            
            // 加载小节数据
            this.loadData()
        },
        async loadData() {
            if (this.filename == "") {
                let res = await genResId()
                if (res && res.data) {
                    this.resId = res.data.resId
                    this.filename = this.resId
                }

            } else {
                this.isUploaded = true

                this.$nextTick(() => {
                    this.loadPdf()
                })
            }
        },
        async loadPdf() {
            let that = this

            if (this.filename == '') {
                return
            }

            this.isLoading = true

            this.url = "https://doc-1253970464.cos.ap-beijing.myqcloud.com/pdf/" + this.filename + ".pdf"

            this.pdfDoc = pdf.createLoadingTask(this.url);
            this.pdfDoc.onProgress = this.onProgress

            // console.log(this.pdfDoc)
            this.pdfDoc.promise.then(pdf => {
                that.numPages = pdf.numPages

            }, function(exception) {
                that.isLoading = false
                that.isUploaded = false
            })
        },
        onProgress(progress) {
            // console.log(progress)
            // this.progress = Number(progress.loaded / progress.total).toFixed(2) * 100
            if (progress.loaded == progress.total) {
                this.isLoading = false
            }
        },
        async uploadSuccess() {
            await saveAdminSectionRes({ cid: this.cid, sid: this.sid, resId: this.resId })

            this.isUploading = false
            this.isUploaded = true

            this.loadPdf()
        },
        prohibit () {
            // console.log(document)
            document.oncontextmenu = function () {
                return false
            }
            document.onkeydown = function (e) {
                if (e.ctrlKey && (e.keyCode === 65 || e.keyCode === 67 || e.keyCode === 73 || e.keyCode === 74 || e.keyCode === 80 || e.keyCode === 83 || e.keyCode === 85 || e.keyCode === 86 || e.keyCode === 117)) {
                    return false
                }
                if (e.keyCode === 18 || e.keyCode === 123) {
                    return false
                }
            }
        },
        handleCmd(data) {
            switch (data.cmd) {
                case 'save':
                    this.save()
                    break
            }
        },
        save() {
            //
        }
    },
    components: {
        Upload,
        pdf,
    }
}
</script>

<style lang="scss" scoped>
.video-js {
    .vjs-big-play-button {
        height: 3em;
        line-height: 3em;
        border-radius: 3em;
        margin-top: -1.5em;
        margin-left: -1.5em;
        .vjs-icon-placeholder {
        &:before {
            font-size: 80px;
        }
        }
    }
}

.video-container {
    height: 100%;
    width: 100%;
    overflow: hidden;

    .content {
        width: 100%;
        height: 100%;
        margin: 0;
        border: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 100;

        .upload-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 100;
        }

        .progress-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .progress-text {
                font-size: 24px;
                font-weight: bold;
                color: #eee;
                margin-bottom: 30px;
            }
        }

        .pdf-wrapper {
            width: 80%;
            height: 100%;
            // padding: 30px 30px;
            overflow-y: hidden;
            .pdf {
                border-bottom: 1px solid #ddd;
            }
        }
    }
}
</style>